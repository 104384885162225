import { ChangeEvent, useEffect, useState, } from "react";
import { Button, Card, Carousel, Col, FloatingLabel, Form, FormControl, Modal, Row } from "react-bootstrap";
import styles from './ap-modal.module.css';
import ArtWork from "@/classes/artwork";
import { useShoppingCart } from "use-shopping-cart";
import { artWorkTestProducts as artWorkProducts } from "@/data/stripeProducts";
import { Product } from "use-shopping-cart/core";
import Image from "next/image";

interface PurchaseOptions {
    "mob_2k": boolean,
    "mob_4k": boolean,
    "mob_8k": boolean,
    "mob_16k": boolean,
    "pc_2k": boolean,
    "pc_4k": boolean,
    "pc_8k": boolean,
    "pc_16k": boolean,
}

function isAddToCartDisabled(selections: PurchaseOptions): boolean {
    for (let key in selections) {
        if (selections.hasOwnProperty(key)) {
            let selected = selections[key as keyof PurchaseOptions];
            if (selected) {
                return false;
            }
        }
    }
    return true;
}

export type MinimumArtWork = {
    id: string, // This is the artwork_id_purchaseKey
    purchaseKey: string,
    url: string,
    alt_description?: string,
    price_id: string,
    price: number
};

export type ApModalProps = {
    artwork: ArtWork,
    show: boolean,
    handleShow: () => void
}

export type foundTypes = {
    priceItemFound: boolean,
    artworkFound: boolean,
};

const initialOptions: PurchaseOptions = {
    "mob_2k": false,
    "mob_4k": false,
    "mob_8k": false,
    "mob_16k": false,
    "pc_2k": false,
    "pc_4k": false,
    "pc_8k": false,
    "pc_16k": false,
};

export default function APModal({artwork, show, handleShow}: ApModalProps) {

    const [liked, setLiked] = useState(false);
    const [nLinked, setNLiked] = useState(0);
    const [options, setOptions] = useState<PurchaseOptions>(initialOptions);
    const [comment, setComment] = useState('');
    const {addItem, removeItem, cartDetails} = useShoppingCart();
    const [clickedAddedToCart, setClickedAddedToCart] = useState(false);
    const [buyBtnText, setBuyBtnText] = useState('Add to Cart');
    const [changed, setChanged] = useState(false);
        
    function handleLikedClick() {
        setLiked(prev => !prev)
        setNLiked(prev => !liked ? prev + 1 : prev - 1);
    }

    function handleComment(e: ChangeEvent<HTMLTextAreaElement>) {
        const currentElement = e.currentTarget;
        setComment(prev => currentElement.value);
    }

    function handleCheckboxClick(event: ChangeEvent<HTMLInputElement>) {
        const element = event.currentTarget;
        const id = element.id as keyof PurchaseOptions;

        setOptions(prev => {
            const newOptions = { ...(prev || {}) } as PurchaseOptions;
            newOptions[id] = !newOptions[id];
            console.log(newOptions);
            return newOptions;
        });
        setChanged(true);
    }
    
    function createProduct(artwork: ArtWork, itemKey: string) {
        const stripeProduct = artWorkProducts.find((value: Product, index: number) => value.name === itemKey);
        // set items to empty, because during the run of the website, any changes made to the status of items property in artWorkProducts is perserved.
        // thus it creates a bug that the items property is not empty when we create a new product.
        stripeProduct!['items'] = [];
        if (!stripeProduct)
            throw Error('Cannot find related Stripe Product.');
    
        stripeProduct['items'] = [{
            id: `${artwork.id}-${itemKey}`,
            purchaseKey: itemKey,
            url: artwork.urls?.regular ?? '',
            alt_description: artwork.alt_description,
            price_id: stripeProduct.price_id,
            price: stripeProduct.price
        } as MinimumArtWork]; 
        stripeProduct.quantity = 1;
    
        return stripeProduct;
    }

    function getCartItemByArtworkIdAndPurchaseKey(artworkId: string, itemKey: string) {
        const initialResult = {
            priceItemFound: false,
            artworkFound: false,
            priceItem: null
        };

        if (!cartDetails) {
            return initialResult;
        }

        // search the itemKey against the name property of every cartDetails.
        let priceItem: Product | null = null;
        for(let key in cartDetails) {
            const name = cartDetails[key].name;
            if (name === itemKey) {
                priceItem = cartDetails[key];
                break;
            }
        }
        if (priceItem) {
            const items = priceItem.items as MinimumArtWork[];
            const combinedKey = `${artworkId}-${itemKey}`;
            const artworkItem = items.find((value: MinimumArtWork, index: number) => value.id === combinedKey);

            if (artworkItem) {
                // The combination of priceItem and artwork is already in the cart.
                return {
                    priceItemFound: true,
                    artworkFound: true,
                    priceItem: priceItem
                };
            }

            return {
                priceItemFound: true,
                artworkFound: false,
                priceItem: priceItem
            };
        }

        return initialResult;
    }


    // function checkChanged() {
    //     const changed = Object.keys(initialSelection).some(key => initialSelection[key as keyof PurchaseOptions] !== options[key as keyof PurchaseOptions]);
    //     setChanged(prev => changed);
    // }

    function handleAddToCart() {
        for(let key in options) {
            const oneOrZero = options[key as keyof PurchaseOptions] === true ? 1 : 0;
            const {priceItemFound, artworkFound, priceItem} = getCartItemByArtworkIdAndPurchaseKey(artwork.id, key);
            const combinedKey = `${artwork.id}-${key}`;

            // 1. oneOrZero === 0 && priceItemFound === false && artworkFound === false
            // => then there's nothing we need to do.
            if (oneOrZero === 0 && !priceItemFound && !artworkFound) {
                continue;
            }
            // 2. oneOrZero === 0 && priceItemFound === true && artworkFound === false
            // => then it means, I did not selecte the current resolution for the current artwork, but I have added the current resultion for other artworks.
            // => then nothing we need to do.
            if (oneOrZero === 0 && priceItemFound && !artworkFound) {
                continue;
            }

            // 3. oneOrZero === 0 && priceItemFound === true && artworkFound === true
            // => then it means, I did not selecte the current resolution for the current artwork, and I have added the current resultion for the current artwork.
            // => then we should remove the current artwork from the cart and quantity reduced by 1.
            if (oneOrZero === 0 && priceItemFound && artworkFound) {
                // 3.1 remove the id,url.regular,itemKey,alt_description of the artwork from the founded priceItem["items"]
                const newPriceItem = {...priceItem};
                newPriceItem["items"] = newPriceItem["items"].filter((value: MinimumArtWork) => value.id !== combinedKey);

                // 3.2 decrease the quantity by 1
                const newQuantity = newPriceItem["items"]?.length ?? 0;
                removeItem(priceItem?.price_id);

                if (newQuantity > 0) {
                    addItem(newPriceItem as Product, {count: newQuantity});
                }
                // checkChanged();
                continue;
            }

            // 4. oneOrZero === 0 && priceItemFound === false && artworkFound === true
            // => this is not possible, because if artworkFound === true, then priceItemFound must be true, since the artwork depends on the priceItem.
            // => then there's nothing we need to do.
            if (oneOrZero === 0 && !priceItemFound && artworkFound) {
                throw Error('This is not possible, because if artworkFound === true, then priceItemFound must be true, since the artwork depends on the priceItem.');
            }            

            // 5. oneOrZero === 1 && priceItemFound === false && artworkFound === false
            // => then it means, I selected the current resolution for the current artwork, but I have not added the current resultion for the current artworks.
            // => then we should add the current artwork into the cart and quantity increased by 1.
            if (oneOrZero === 1 && !priceItemFound && !artworkFound) {
                // 4.1 create a new product and add it to cart
                const stripleProduct = createProduct(artwork, key);
                addItem(stripleProduct!, {count: 1});
                // checkChanged();
                continue;
            }

            // 6. oneOrZero === 1 && priceItemFound === true && artworkFound === false
            // => then it means, I selected the current resolution for the current artwork, and I have not added the current resultion for the current artworks.
            // => then we should add the current artwork into the cart and quantity increased by 1.
            if (oneOrZero === 1 && priceItemFound && !artworkFound) {
                // 5.0 check whether the newly selected artwork is already in the cart.
                // 5.0.1 if it is already in the cart, then we should not add it again.
                const existingArtwork = priceItem!.items.find((value: MinimumArtWork, index: number) => value.id === combinedKey);                                                                    
                if (existingArtwork) {
                    continue;
                }

                // 5.1 add the id,url.regular,itemKey,alt_description of the artwork into the founded priceItem["items"]
                const newPriceItem = {...priceItem};

                newPriceItem.items = [...newPriceItem.items, {
                    id: `${artwork.id}-${key}`,
                    purchaseKey: key,
                    url: artwork.urls?.regular ?? '',
                    alt_description: artwork.alt_description,
                    price_id: priceItem!.price_id,
                    price: priceItem!.price
                } as MinimumArtWork];
                // 5.2 increase the quantity by 1;
                // The reason why we need to remove the priceItem entirely before adding a new artwork is
                // because the before and after priceItem has the same price_id, so the useShoppingCart() will not
                // detect any changes and will not update the cartDetails.
                removeItem(priceItem?.price_id);

                if(newPriceItem.items.length > 0) {
                    addItem(newPriceItem as Product, {count: newPriceItem.items.length + 1});
                }
                // checkChanged();
                continue;
            }

            // 7. oneOrZero === 1 && priceItemFound === true && artworkFound === true
            // => then it means, I selected the current resolution for the current artwork, and I have already added the current resultion for the current artworks.
            // => then nothing we need to do.
            if (oneOrZero === 1 && priceItemFound && artworkFound) {
                continue;
            }

            // 8. oneOrZero === 1 && priceItemFound === false && artworkFound === true
            // => this is not possible, because if artworkFound === true, then priceItemFound must be true, since the artwork depends on the priceItem.
            // => then there's nothing we need to do.
            if (oneOrZero === 1 && !priceItemFound && artworkFound) {
                throw Error('This is not possible, because if artworkFound === true, then priceItemFound must be true, since the artwork depends on the priceItem.');
            }
        }

        setChanged(false);
        setClickedAddedToCart(true);
        setTimeout(() => setClickedAddedToCart(false), 6000);
    }

    function getSelectedPurchaseOptionsByArtworkIdFromCartDetails(artworkId: string) {
        const initialSelections = {
            "mob_2k": false,
            "mob_4k": false,
            "mob_8k": false,
            "mob_16k": false,
            "pc_2k": false,
            "pc_4k": false,
            "pc_8k": false,
            "pc_16k": false,
        };
        
        if (!cartDetails || !artworkId) {
            return initialSelections;
        }

        let result = initialSelections;
        for(let priceId in cartDetails) {
            const priceItem = cartDetails[priceId];
            const items = priceItem.items as MinimumArtWork[];
            const artworkItem = items.find((value: MinimumArtWork, index: number) => value.id === `${artworkId}-${priceItem.name}`);
            if (artworkItem) {
                result[priceItem.name as keyof PurchaseOptions] = true;
            }
        }

        return result;
    }

    useEffect(() => {
        if(show) {
            const selectedOptions = getSelectedPurchaseOptionsByArtworkIdFromCartDetails(artwork.id);
            setOptions(prev => selectedOptions);
            const anyOptionSelected = Object.values(selectedOptions).some((value: boolean) => value);
            setBuyBtnText(anyOptionSelected ? 'Update Cart' : 'Add to Cart');
            setChanged(false);
        }
    }, [show]);

    return (
        <Modal show={show} onHide={handleShow} fullscreen={'sm-down'} backdrop='static' backdropClassName={styles.semiBackdrop}>
            <Modal.Header closeButton>
            <div className="text-black mb-0 p-0">
                <span className="fs-8">{artwork?.alt_description}</span>
                {/* <span className="fs-3">Jungle Forest & Waterfall</span> */}
            </div>
            </Modal.Header>
            <Modal.Body className="m-0 p-1">
                <Card bg='Dark' className="m-0 p-0">
                    {/* <Card.Img variant="top" src={artwork?.urls?.full} /> */}
                    <Carousel>
                        <Carousel.Item>
                            <Card.Img variant="top" src={artwork?.urls?.full} className="m-0 p-0"/>
                            <Carousel.Caption>
                                <h3>1st</h3>
                                <p>{artwork.alt_description}</p>
                            </Carousel.Caption>
                        </Carousel.Item>        
                        <Carousel.Item>
                            {/* <Card.Img variant="top" src='imgs/02.jpg' className="m-0 p-0"/> */}
                            <Image src='/imgs/02.jpg' width={600} height={375} sizes="100vw" style={{width: '100%', height: 'auto'}} className="m-0 p-0" alt={artwork.alt_description ?? ''} />
                            <Carousel.Caption>
                                <h3>2nd</h3>
                                <p>{artwork.alt_description}</p>
                            </Carousel.Caption>
                        </Carousel.Item>        
                    </Carousel>
                    <Card.ImgOverlay className="m-0 p-0">
                   
                        <Card.Text className="text-white p-2">
                            {artwork?.description}
                            {/* The beautiful rainforest with waterfull which flows into your heart, great for peaceful mind and relexation. */}
                        </Card.Text>
                        <div className={styles.artLike} onClick={handleLikedClick}>
                            {
                                liked ? 
                                (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="red"  viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"/>
                                    </svg>  
                                ) : 
                                (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="red" viewBox="0 0 16 16">
                                        <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15"/>
                                    </svg>  
                                )
                            }&nbsp;&nbsp;&nbsp;&nbsp;
                            <i className="text-white" style={{lineHeight:"32px", verticalAlign:"middle", font: "36px bold red"}}>{nLinked}</i>
                        </div>
                        {/* <Card.Img src="/imgs/winston-profile.jpeg" className={styles.profileImg + ' rounded-circle position-absolute'} /> */}
                        <Card.Img src={artwork.user?.profile_image?.small ?? "/imgs/winston-profile.jpeg"} className={styles.profileImg + ' rounded-circle position-absolute'} />                        
                    </Card.ImgOverlay>
                    <Card.Body className="m-0 p-0"></Card.Body>
                </Card>
                                    
                <Row className="mt-2 mb-2">
                    <Col className="col text-dark ms-1" style={{fontSize: '14px'}}>
                        This digital art is also available in <span className="fw-bolder fst-italic">Paper Poster, Wooden Framed Poster, Metal Framed Poster, Mobile Phone Case, Hoodies, T-shirts, Mugs</span>. <br />
                        To see these variants, please visit <a href="https://artfullpixelsau.etsy.com?source=myownwebsite" style={{color:"darkgoldenrod"}} target="_blank" rel="noreferrer">My ArtfullPixels AU Store</a>. <br />
                    </Col>
                </Row>
                
                <Row align='left'>
                    <Col className="col-2">
                        <Card.Text className={styles.available + " position-relative start-50 mt-1"}>
                            <i className="bi bi-phone fs-2 text-dark" />
                        </Card.Text>
                    </Col>
                    <Col className="col-10" align="left">
                        <Form className={styles.mobileResOptions}>
                            <Form.Check inline onChange={(e) => handleCheckboxClick(e)} checked={options["mob_2k"]}
                                className="p-1" label="2K $5" name="mobile" type="checkbox" id="mob_2k" />
                            <Form.Check inline onChange={(e) => handleCheckboxClick(e)} checked={options["mob_4k"]}
                                className="p-1" label="4K $15" name="mobile" type="checkbox" id="mob_4k" /> <br />
                            <Form.Check inline onChange={(e) => handleCheckboxClick(e)} checked={options["mob_8k"]}
                                className="p-1" label="8K $80" name="mobile" type="checkbox" id="mob_8k" />
                            <Form.Check inline onChange={(e) => handleCheckboxClick(e)} checked={options["mob_16k"]}
                                className="p-1" label="16K $120" name="mobile" type="checkbox" id="mob_16k" />
                        </Form>
                    </Col>
                    <Col className="col-11">
                        <Form className={styles.mobileResOptions + " m-0 p-0"}>
                            
                        </Form>
                    </Col>
                </Row>
                <hr className="m-2 mb-3" />
                <Row align='left'>
                    <Col className="col-2">
                        <Card.Text className={styles.available + " position-relative start-50 mt-1"}>
                            <i className="bi bi-laptop fs-2 text-dark" />
                        </Card.Text>
                    </Col>
                    <Col className="col-10">
                        <Form className={styles.mobileResOptions}>
                            <Form.Check inline onChange={(e) => handleCheckboxClick(e)} checked={options["pc_2k"]}
                                className="p-2" label="2K $5" name="pc" type="checkbox" id="pc_2k" />
                            <Form.Check inline onChange={(e) => handleCheckboxClick(e)} checked={options["pc_4k"]}
                                className="p-2" label="4K $15" name="pc" type="checkbox" id="pc_4k" /> <br />
                            <Form.Check inline onChange={(e) => handleCheckboxClick(e)} checked={options["pc_8k"]}
                                className="p-2" label="8K $80" name="pc" type="checkbox" id="pc_8k" />
                            <Form.Check inline onChange={(e) => handleCheckboxClick(e)} checked={options["pc_16k"]}
                                className="p-2" label="16K $120" name="pc" type="checkbox" id="pc_16k" />
                        </Form>
                    </Col>
                </Row>
                <hr className="m-2 mb-3" />
                {/* Free Download & Add to Cart */}
                <Row className="m-2" align='middle'>
                    <Col className="">
                        <a className="btn btn-primary" href="/imgs/winston-profile.jpeg" download>Free Download</a>
                    </Col>
                    <Col className="">
                        <Button variant="warning" id="buyBtn" disabled={!changed} onClick={handleAddToCart}>{buyBtnText}</Button>
                    </Col>
                </Row>
                {
                    clickedAddedToCart && (
                        <Row className={styles.addedToCart}>
                            <Col className="col-12">
                                <span className="text-success">Cart updated</span>
                            </Col>
                        </Row>
                    )
                }
                <hr className="text-dark m-2 mt-3" />  
                {/* Comments */}
                <Row>
                    <Col>
                        <span style={{color:"black"}} className="m-2">Comment:</span>
                    </Col>
                </Row>
                <Row className="pb-3">
                    <Col>
                        <FloatingLabel
                            className={styles.floatingPlaceholder}
                            controlId="likeComment"
                            label={"Got an idea for new wallpapers? We'll get it."}>

                            <FormControl className={styles.comment} as="textarea" onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleComment(e)}
                                style={{ height: '100px' }} placeholder="" value={comment} />                            
                        </FloatingLabel>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}