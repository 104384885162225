import { Card } from "react-bootstrap";
import ArtWork from "@/classes/artwork";


export type APCardProps = {
    artwork: ArtWork,
}

export default function APCardWithModal({artwork}: APCardProps) {
    
    return (
        <Card key={artwork.id} className="text-white m-2" 
            style={{backgroundColor: "transparent"}}>
            <Card.Img src={artwork.urls?.regular} alt={artwork.alt_description} />
            <Card.ImgOverlay>
                <Card.Title>{artwork.alt_description}</Card.Title>
                <Card.Text>
                    {artwork.description}
                </Card.Text>
                <Card.Text>Created By: {artwork.user?.name}</Card.Text>
            </Card.ImgOverlay>
        </Card>
    );
}