import ArtWork from "@/classes/artwork";
import { debounce } from "lodash";

export const UNSPLASH_BASE_URL = 'https://api.unsplash.com';

export default async function fetchImages(page=0) {
  try{
    const response = await fetch(`${UNSPLASH_BASE_URL}/search/photos?query=tea&page=${page}`, {
      headers: {
        Authorization: `Client-ID ${process.env.NEXT_PUBLIC_UNSPLASH_ACCESSKEY}`
      }
    });
    const { total, total_pages, results } = await response.json();
    const artworks:Array<ArtWork> = [];
    for(let inx in results) {
      artworks.push(new ArtWork(results[inx]));
    }
    return {total, total_pages, artworks};
  } catch(err) {
    console.error("------ERROR: ", err);
  } 
}

export const getImage = async (id:string) => {
  const response = await fetch(`${UNSPLASH_BASE_URL}/photos/${id}`, {
    headers: {
      Authorization: `Client-ID ${process.env.NEXT_PUBLIC_UNSPLASH_ACCESSKEY}`
    }
  });

  const artwork = await response.json();

  return new ArtWork(artwork);
};

// const fetchImages = debounce(async (page:number = 0) => {
//   const response = await fetch(`${BASE_URL}?query=tea&page=${page}`, {
//     headers: {
//       Authorization: `Client-ID ${process.env.NEXT_PUBLIC_UNSPLASH_ACCESSKEY}`
//     }
//   });
//   console.log('------------------------>>>>>>>>>>>', process.env.UNSPLASH_ACCESSKEY);
//   const { results } = await response.json();
//   const artworks:Array<ArtWork> = [];
//   for(let img in results) {
//     artworks.push(new ArtWork(img));
//   }
//   return artworks;
// }, 500);

// export default fetchImages;