import ArtWork from '@/classes/artwork';
import fetchImages from '@/utils/datasource';
import React, { useState, useRef, useEffect } from 'react';
import APCard from './ap-card';
import { Col, Row } from 'react-bootstrap';
import APModal from './ap-modal';
import APCardWithModal from './ap-cardWithModal';

export default function InfiniteScroll() {
  const [data, setData] = useState<ArtWork[]>([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(new ArtWork(null));
  const bottomRef = useRef(null);

  function handleShow() {
    setShowModal(prev => !prev);
  }

  async function loadMore() {
      setLoading(true);
      try {
        const response = await fetchImages(page);
        if (!response || !response.artworks || response.artworks.length === 0) {
          return;
        }

        const {total, total_pages, artworks} = response;
        setData(prevData => [...prevData, ...artworks]);
        setPage(prevPage => prevPage + 1);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
        console.log("---------->Images: ", data.length);
      }
  }

  function handleCardClick(item: ArtWork) {
    setSelectedImage(prev => item);
    handleShow();
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          loadMore();
        }
      },
      { threshold: 1 }
    );

    if (bottomRef.current) {
      observer.observe(bottomRef.current);
    }

    return () => {
      if (bottomRef.current) {
        observer.unobserve(bottomRef.current);
      }
    };
  }, [page]);

  return (
    <div className="infinitescroll mt-2">
      <Row xs={1} md={2} lg={3} xlg={4} xxl={5} className="g-4">
        {data.map((item, index) => (
          <Col key={item.id} onClick={() =>handleCardClick(item)}>
            <APCardWithModal 
              artwork={item}
            />
          </Col>
        ))}
      </Row>
      <div ref={bottomRef}>
        <hr style={{borderTop: 'dotted 1px'}} />
      </div>
      {loading && <p>Loading...</p>}
      <APModal 
        artwork={selectedImage}
        show={showModal}
        handleShow = {handleShow} />
    </div>
  );
}