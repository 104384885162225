import User from "./user";

export interface ArtWorkURLs {
    raw: string;
    full: string;
    regular: string;
    small: string;
    thumb: string;
    small_s3: string;
};

export interface ArtWorkLinks {
    self: string;
    html: string;
    download: string;
    download_location: string;
};

export type TopicSubmissions = {
    [key: string]: {
        status: string;
        approved_on: string;
    };
};

export interface Tag {
    type: string;
    title: string;
    source?: any;
};

export default class ArtWork {
    id: string = '';
    slug: string = '';
    created_at: string = '';
    updated_at: string = '';
    promoted_at: string = '';
    width: number = 0;
    height: number = 0;
    color: string = '';
    blur_hash: string = '';
    description: string | null = null;
    alt_description: string | undefined = undefined;
    breadcrumbs: any[] = [];
    urls: ArtWorkURLs | undefined;
    links: ArtWorkLinks| undefined;
    likes!: number;
    liked_by_user: boolean = false;
    current_user_collections: any[] = []; // Specify the correct type if known
    sponsorship: any | null; // Specify the correct type if known
    topic_submissions!: TopicSubmissions;
    user!: User;
    tags: Array<Tag> = [];
      
    constructor(jsonObj: any) {
        if(jsonObj) {
            // Assign values from jsonObj to this instance
            Object.assign(this, jsonObj);
            this.alt_description = jsonObj.alt_description;
        }
    }
}
      
  