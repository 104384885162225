import { Product } from "use-shopping-cart/core";

// export interface ArtWorkProduct extends Product {
//     id: string,
//     name: string,
//     description: string,
//     price: number,
//     currency: string,
//     quantity: number,
// }
export const artWorkProducts: Product[] = [
    {
        price_id:'price_1OJFVPB7ufvhRNsanbuOXgZw',
        name: 'mob_2k',
        description: "",
        price: 500,
        currency: 'AUD',
        quantity: 0
    },
    {
        price_id:'price_1OKYiXB7ufvhRNsayPqYHrMi',
        name: 'mob_4k',
        description: "",
        price: 1500,
        currency: 'AUD',
        quantity: 0
    },
    {
        price_id:'price_1OKYleB7ufvhRNsaAhQIY0Gn',
        name: 'mob_8k',
        description: "",
        price: 8000,
        currency: 'AUD',
        quantity: 0
    },
    {
        price_id:'price_1OKYmWB7ufvhRNsaMU4qGClS',
        name: 'mob_16k',
        description: "",
        price: 12000,
        currency: 'AUD',
        quantity: 0
    },
    {
        price_id:'price_1OKYngB7ufvhRNsalnyX80Pr',
        name: 'pc_2k',
        description: "",
        price: 500,
        currency: 'AUD',
        quantity: 0
    },
    {
        price_id:'price_1OKYuPB7ufvhRNsaFEmIToZk',
        name: 'pc_4k',
        description: "",
        price: 1500,
        currency: 'AUD',
        quantity: 0
    },
    {
        price_id:'price_1OKYwLB7ufvhRNsagBIKdHMn',
        name: 'pc_8k',
        description: "",
        price: 8000,
        currency: 'AUD',
        quantity: 0
    },
    {
        price_id:'price_1OKYx0B7ufvhRNsabNGtl6Mv',
        name: 'pc_16k',
        description: "",
        price: 12000,
        currency: 'AUD',
        quantity: 0
    }
];

export const artWorkTestProducts: Product[] = [
    {
        price_id:'price_1OKwMcB7ufvhRNsaX9jOHuqz',
        name: 'mob_2k',
        description: "",
        price: 500,
        currency: 'AUD',
        quantity: 0
    },
    {
        price_id:'price_1OKwNkB7ufvhRNsaeMUMLtLH',
        name: 'mob_4k',
        description: "",
        price: 1500,
        currency: 'AUD',
        quantity: 0
    },
    {
        price_id:'price_1OKwNxB7ufvhRNsaxLYWyk6Y',
        name: 'mob_8k',
        description: "",
        price: 8000,
        currency: 'AUD',
        quantity: 0
    },
    {
        price_id:'price_1OKwnPB7ufvhRNsa5Aja15xY',
        name: 'mob_16k',
        description: "",
        price: 12000,
        currency: 'AUD',
        quantity: 0
    },
    {
        price_id:'price_1OLdnLB7ufvhRNsafzgqAUWP',
        name: 'pc_2k',
        description: "",
        price: 500,
        currency: 'AUD',
        quantity: 0
    },
    {
        price_id:'price_1OLdo3B7ufvhRNsax8yAvLsZ',
        name: 'pc_4k',
        description: "",
        price: 1500,
        currency: 'AUD',
        quantity: 0
    },
    {
        price_id:'price_1OLdoLB7ufvhRNsa0K7F8WgV',
        name: 'pc_8k',
        description: "",
        price: 8000,
        currency: 'AUD',
        quantity: 0
    },
    {
        price_id:'price_1OLdoaB7ufvhRNsaLw76bGZg',
        name: 'pc_16k',
        description: "",
        price: 12000,
        currency: 'AUD',
        quantity: 0
    }
];