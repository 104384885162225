'use client';

import InfiniteScroll from '@/components/ap-infiniteScroll';
import styles from './page.module.css'

export default function Home() {

  return (
    <main className="container-fluid p-0">
      <div className={styles.hero + " row"}>
        <h2 className={styles.hero_p + ' col-10 offset-1'}>
          The best website to seek artful wallpapers for your mobile phones.
        <p className='col-10 offset-2 fs-3'>New Wallpapers coming everyday.</p>
        </h2>
      </div>
      <div className="gallery">
        <InfiniteScroll />
      </div>
    </main>
  )
}
